import AlephBet from "alephbet"
import {
  storage_adapter,
  super_properties_lamed_adapter
} from "./adapters"
import {
  get_user_id,
  on_study_unit,
  on_dashboard,
  logged_in_user,
  logged_in_potential_customer
} from "./helpers"
import {
  quiz_or_video_started,
  pricing_page_visited,
  checkout_page_visited,
  premium_subscription_started
} from "./goals"

export default () => {
  const product_tours = new AlephBet.Experiment({
    name: "202503 Product tours",
    tracking_adapter: super_properties_lamed_adapter,
    storage_adapter: storage_adapter,
    user_id: get_user_id(),
    trigger: () =>
      (on_study_unit() || on_dashboard()) &&
      logged_in_potential_customer() &&
      // Thu, 20 Mar 2025 12:00:00 +0000
      logged_in_user().confirmed_at > 1742472000,
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Tours": {
        activate: experiment => {
          if (!experiment.options.trigger()) return

          window.gon.show_product_tours = true
        }
      }
    }
  })
  product_tours.add_goals([
    quiz_or_video_started,
    pricing_page_visited,
    checkout_page_visited,
    premium_subscription_started
  ])
}
