import AlephBet from "alephbet"
import {storage_adapter, super_properties_lamed_adapter} from "./adapters"
import {has_role, on_study_unit, get_user_id} from "./helpers"
import {premium_subscription_started, pricing_page_visited} from "./goals"
import modal from "../modal"

export default () => {
  const new_premium_banner_on_study_units = new AlephBet.Experiment({
    name: "202502 New Premium Banner on study units",
    tracking_adapter: super_properties_lamed_adapter,
    user_id: get_user_id(),
    storage_adapter: storage_adapter,
    trigger: () => has_role("user") && on_study_unit(),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-New-premium-banner": {
        activate: (experiment) => {
          if (!experiment.options.trigger()) return

          // Stop the banner from loading until the user has seen the tour
          if (!window.gon.completed_tours.includes("study_unit_tour")) return

          if (window.gon.study_unit_views > 1) {
            modal("#upgrade_to_premium_modal")
          }
        }
      }
    }
  })

  new_premium_banner_on_study_units.add_goals([
    premium_subscription_started,
    pricing_page_visited
  ])
}
