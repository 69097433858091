// Always make sure that we don't see a content flicker when creating a new
// a/b test. You can avoid this by adding the css class "async-hide" to the
// html tag. This will hide the page until the page load event is triggered.
// Be aware that this would also increase the LCP (Largest Contentful Paint)
// time for this page. If possible, only hide the part of the page which is
// actually tested.

import {isbot} from "isbot"
import storage from "storage"
import AlephBet from "alephbet"
import page_load from "page_load"
import supported_browsers from "supported_browsers"
import {check_goals} from "./goals"
import visual_checkout_page from "./visual_checkout_page.js"
import improved_su_discovery_from_articles from
  "./improve-su-discovery-from-articles"
import new_premium_banner_on_study_units from
  "./new_premium_banner_on_study_units.js"
import product_tours from "./product_tours"

(() => {
  // not running experiment in test mode.
  if (window.gon.javascript_test_env) return

  // not running unless local storage is available
  if (!storage.enabled) return

  // only run the A/B test for supported browsers on production
  if (
    window.gon.rails_env === "production" &&
    !supported_browsers.test(window.navigator.userAgent)
  ) {
    return
  }

  // don't run experiments for bots
  if (isbot(window.navigator.userAgent)) return

  if (window.gon.alephbet_debug) AlephBet.options.debug = true

  return page_load.on_ready(() => {
    visual_checkout_page()
    improved_su_discovery_from_articles()
    new_premium_banner_on_study_units()
    product_tours()
    check_goals()
  })
})()
